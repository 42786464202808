<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="">
    <b-row class="mb-1">
      <b-col cols="12" md="12">
        <div class="d-flex justify-content-end">
          <b-button variant="primary" v-b-modal.modal-new-helpdesk>
            <span class="text-nowrap"> Create Helpdesk </span>
          </b-button>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="clients.length > 0 && selectedClient == null">
      <b-col cols="12" sm="6" md="4" lg="4" xl="3" v-for="client in clients" :key="client.id">
        <div class="card" @click="selectClient(client)" style="min-height: 220px;">
          <div class="banner" :style="'background-image: url(' +  (client.logoLink ? client.logoLink : companiesURL + 'placeholder.jpg') + ');'"></div>
          <h2 class="mt-2 pr-1 pl-1 text-center" style="font-size: 1.2rem;">
            {{ client.client_name }}
          </h2>
          <div class="title">
            <strong>{{ client.helpdesks.length }} Helpdesk</strong>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr>

   

    <div class="carousel-container" v-if="clients.length > 0 && selectedClient">
    <b-button @click="scrollLeft" class="carousel-button left-button" v-if="clients.length > 12">&lt;</b-button>

    <div class="carousel-wrapper">
      <b-row class="carousel-content" >
        <b-col cols="12" sm="1" md="1" lg="1" xl="1" v-for="client in clients" :key="client.id">
          <div class="card d-flex justify-content-center align-items-center" @click="selectClient(client)" style="max-height: 110px;">
            <div class="banner" :style="'width:50%;background-image: url(' +  (client.logoLink ? client.logoLink : companiesURL + 'placeholder.jpg') + ');'"></div>
            <small v-if="!client.logoLink" class="mt-2 pr-1 pl-1 text-center" style="font-size: 0.6rem;">
              {{ client.client_name }}
            </small>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-button v-show="clients.length > 12" @click="scrollRight" class="carousel-button right-button">&gt;</b-button>
  </div>


    
    <b-card v-if="selectedClient != null">
            <h2 class="clientTitle mb-1" >{{  selectedClient.client_name }}</h2>

            <span @click="activeTab = 1" :class="{ activeMenu: activeTab === 1, passiveMenu: activeTab !== 1 }"> <i class="fa-solid fa-table-columns mr-25 "></i> Helpdesks </span>
            <span @click="activeTab = 2" :class="{ activeMenu: activeTab === 2, passiveMenu: activeTab !== 2 }"> <i class="fa-solid fa-border-all mr-25 "></i> Users </span>
          

            <hr />

            <div v-if="activeTab == 1" style="min-height:500px">
              <div class="cardClass">
                
                <b-row v-if="selectedClient.helpdesks.length>0">
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="4"
                    xl="3"
                    v-for="helpdesk in selectedClient.helpdesks"
                    :key="helpdesk.id"
                  >
                    <div
                      class="card"
                      @click="redirectPreview(helpdesk.id)"
                      style="min-height: 220px;"
                    >
                      <div
                        class="banner"
                        :style="'background-image: url(' + (helpdesk.logoLink ? helpdesk.logoLink : companiesURL + 'placeholder.jpg') + ');'"
                      ></div>
                      <h2 class="mt-2 pr-1 pl-1 text-center" style="font-size: 1.2rem;">
                        {{ helpdesk.project_name }}
                      </h2>
                      <div class="title">
                        <strong>{{ helpdesk.client_name }}</strong>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <div class="text-center text-muted" v-else><h2 class="text-muted mt-5">There are no helpdesk</h2></div>

               
              </div>
            </div>

            <div v-if="activeTab == 2" style="min-height:500px">
              <b-row>
            <!-- Per Page -->
                <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
               
                </b-col>

                <b-col cols="12" md="5" v-if="$Can('user_add_btn')">
                  <div class="d-flex align-items-center justify-content-end mb-2">
                    <b-button variant="primary" router-link :to="{ path: '/helpdesks/add-login', query: { clientId: selectedClient.client_id } }">
                      <span class="text-nowrap">Add User</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>

              <b-table
                striped
                hover
                ref="refUserListTable"
                head-variant="dark"
                class="position-relative"
                :items="selectedClient.users"
                selectable
                select-mode="single"
                responsive
                :small="false"
                :fields="tableColumns"
                show-empty
                empty-text="No matching records found"
                @row-selected="onRowSelected"
              >
                <!-- Column: Id -->
                <template #cell(id)="data">
                  <strong class="text-primary">#{{ data.value }}</strong>
                </template>

                <!-- Column: Name -->
                <template #cell(name)="data">
                  {{ data.value }}
                </template>

                <!-- Column: Name -->
                <template #cell(surname)="data">
                  {{ data.value }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-center">
                    <b-button @click="deleteClientLogin(data.item)" variant="danger">
                      <feather-icon icon="TrashIcon" size="24"  class=" " />

                    </b-button>
                  </div>
                </template>
              </b-table>

            </div>

         
          </b-card>



    <!-- <b-row v-if="helpdesks.length > 0">
      <b-col cols="12" sm="6" md="4" lg="4" xl="3" v-for="helpdesk in helpdesks" :key="helpdesk.id">
        <div class="card" @click="redirectPreview(helpdesk.id)" style="min-height: 220px;">
          <div class="banner" :style="'background-image: url(' +  (helpdesk.logoLink ? helpdesk.logoLink : companiesURL +  'placeholder.jpg') + ');'"></div>
          <h2 class="mt-2 pr-1 pl-1 text-center" style="font-size: 1.2rem;">
            {{ helpdesk.name }}
          </h2>
          <div class="title">
            <strong>{{ helpdesk.client_name }}</strong>
          </div>
        </div>
      </b-col>
    </b-row> -->

    

    <b-modal id="modal-new-helpdesk" @ok="handleHelpdeskOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="md" ok-title="Save" centered title="Create new helpdesk">
      <validation-observer ref="helpdeskRules">
        <b-form>
          <b-form-group>
            <label for="helpdeskName">Projects</label>
            <validation-provider name="Project Name" #default="{ errors }" rules="required">
              <v-select id="helpdeskName" v-model="selectedProject" :options="formattedProjects" placeholder="Select a project" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import helpdeskStoreModule from './helpdeskStoreModule';
import vSelect from 'vue-select';
import VueContext from 'vue-context';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import { formatDate, getTeamClass } from '../helpers';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import router from "@/router";
import axiosIns from '@/libs/axios';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    VueContext,
    BBreadcrumb,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const HELPDESK_APP_STORE_MODULE_NAME = 'helpdesk';
    // Register module
    if (!store.hasModule(HELPDESK_APP_STORE_MODULE_NAME)) store.registerModule(HELPDESK_APP_STORE_MODULE_NAME, helpdeskStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HELPDESK_APP_STORE_MODULE_NAME)) store.unregisterModule(HELPDESK_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      searchText: '',

      companiesURL: store.state.app.companiesURL,
      loading: true,
      helpdesks: [],
      projects: [],
      selectedProject: null,
      clients:[],
      selectedClient:null,
      activeTab:1,
      tableColumns: [
        { key: "id", sortable: true, class: "text-center" },
        { key: "name", sortable: true, class: "text-center" },
        { key: "surname", sortable: true, class: "text-center" },
        { key: "email", sortable: true, class: "text-center" },
        { key: "actions", sortable: false, class: "text-center" },
      ],
      scrollAmount:0,
    };
  },

  mounted() {
    this.getHelpDesks();
  },

  watch: {},

  methods: {
    formatDate,
    getTeamClass,

    getHelpDesks() {
      this.loading = true;

      store
        .dispatch('helpdesk/getHelpdeskAll')
        .then((res) => {
          this.clients = res.data.clients;
          this.helpdesks = res.data.helpdesks;
          this.projects = res.data.projects;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    selectClient(client) {
      this.selectedClient =  this.selectedClient == client ? null : client;
      this.scrollToHelpdesks();
    },
    scrollToHelpdesks() {
      this.$nextTick(() => {
        const helpdeskSection = this.$el.querySelector('.helpdesk-section');
        if (helpdeskSection) {
          helpdeskSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },


    redirectPreview(_id) {
      console.log(_id);
      this.$router.push({ name: 'helpdesk-preview', params: { id: _id } });
    },

    handleHelpdeskOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      this.$refs.helpdeskRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          console.log(this.selectedProject);
          store
            .dispatch('helpdesk/saveHelpdesk', this.selectedProject)
            .then((res) => {
              this.getHelpDesks();
              this.$bvModal.hide('modal-new-helpdesk');
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Folder has been created',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    onRowSelected(item) {
      router.push({ name: 'edit-login', params: { id: item[0].id }, query:{clientId:this.selectedClient.client_id} });
    },

    
    deleteClientLogin(item) {
      axiosIns
      .delete(`deleteClientLogin/${item.id}`)
        .then((res) => {
          let userList = this.selectedClient.users.filter((e)=>{return e.id != item.id});
          this.selectedClient.users = userList;
          
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },


    resetModal() {
      this.selectedProject = null;
    },

    scrollLeft() {
      const scrollWrapper = this.$el.querySelector('.carousel-wrapper');
      const scrollContent = this.$el.querySelector('.carousel-content');

      if (this.scrollAmount > 0) {
        this.scrollAmount -= scrollWrapper.clientWidth;
      }

      scrollContent.style.transform = `translateX(-${this.scrollAmount}px)`;
    },
    scrollRight() {
      const scrollWrapper = this.$el.querySelector('.carousel-wrapper');
      const scrollContent = this.$el.querySelector('.carousel-content');
      const maxScroll = scrollContent.scrollWidth - scrollWrapper.clientWidth;

      if (this.scrollAmount < maxScroll) {
        this.scrollAmount += scrollWrapper.clientWidth;
      }

      scrollContent.style.transform = `translateX(-${this.scrollAmount}px)`;
    },
  
  },

  computed: {
    formattedProjects() {
      return this.projects.map((project) => {
        return {
          value: project.id,
          label: `${project.name} (${project.client_name})`,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  // transform: scale(1.01);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card {
  background-color: #fff;
  // max-width: 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0rem;
  border: solid 1px #00000025;
}
.card .banner {
  // background-image: url('~@/assets/images/placeholder.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  // background-color: #00000010;
  margin: 10px 40px 0px 40px;

  //top - right - bottom - left

  // border-bottom: solid 1px #00000020;
}

.card h2.name {
  text-align: center;
  margin: 0;
  padding: 0rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: normal;
  font-size: 1.225rem;
}
.card .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
}
.card .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  order: 99;
}
.card .actions .task-info {
  padding: 0 0 1rem;
  display: flex;
}
.card .actions .task-info h2 {
  text-align: center;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}
.card .actions .task-info h2 a {
  text-decoration: none;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  transition: background-color 100ms ease-in-out;
}
.card .actions .task-info h2 a span {
  color: #73c0d2;
  font-weight: bold;
  transform-origin: bottom;
  transform: scaleY(1.3);
  transition: color 100ms ease-in-out;
}
.card .actions .task-info h2 a small {
  color: #afafaf;
  font-size: 0.85rem;
}

.card .desc {
  text-align: justify;
  padding: 0 2rem 2.5rem;
  order: 100;
}

.carousel-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.carousel-wrapper {
  overflow: hidden;
  width: 100%;
}

.carousel-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  flex-wrap: nowrap;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 1.5rem;
  z-index: 1;
}

.left-button {
  left: 0;
}

.right-button {
  right: 0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
